import React from "react"

const FormBodyContainer = ({ children }) => {
  return (
    <main className="overflow-hidden pt-18 pb-40 laptop-standard:pt-40 laptop-standard:pb-[350px]">
      <section className="bg-primaryYellow relative form-background pt-18 pb-41 px-8 laptop-standard:pt-20 laptop-standard:pb-33 laptop-standard:px-40">
        {children}
      </section>
    </main>
  )
}

export default FormBodyContainer
