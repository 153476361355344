import React from "react"

const FormMainTitle = ({ children, className }) => {
  return (
    <h2
      className={`font-bold text-6lg leading-8 tracking-normal text-primaryGreen laptop-standard:text-5xl laptop-standard:leading-15 ${className}`}
    >
      {children}
    </h2>
  )
}

export default FormMainTitle
