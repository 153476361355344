import React from "react"
import FormMainTitle from "../atoms/textStyledComponents/FormMainTitle"
import FormBodyContainer from "../organism/FormBodyContainer"

const ApplicationConfirmationBody = ({ statusSuccess }) => {
  console.log("statusSuccess", statusSuccess)
  return (
    <FormBodyContainer>
      <div className="flex flex-col gap-10 justify-center text-center items-center laptop-standard:items-start laptop-standard:text-start min-h-25 laptop-standard:min-h-65 ">
        {statusSuccess ? (
          <FormMainTitle className="text-8lg laptop-standard:text-6xl mt-10">
            Thank You
          </FormMainTitle>
        ) : (
          <>
            <FormMainTitle className="text-8lg laptop-standard:text-6xl mt-10">
              Please try again
            </FormMainTitle>
            <p className="laptop-standard:text-4lg laptop-standard:leading-7">
              An error has ocurred while processing your application.
            </p>
          </>
        )}
      </div>
    </FormBodyContainer>
  )
}

export default ApplicationConfirmationBody
